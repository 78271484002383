import React, { Component } from 'react'
import { StaticQuery, graphql, Link } from 'gatsby'
import { StyleSheet, css } from 'aphrodite'
import Scroll from 'react-scroll'
import Helmet from 'react-helmet'

import AltertineLayout from './layouts/AltertineLayout'

import Button from '@material-ui/core/Button'

import Classes from '../styles/classes'
import i18next from '../config/i18n/context'

const styles = StyleSheet.create({
  footer: {
    backgroundColor: '#002337'
  },
  button: {
    display: 'block',
    margin: '1em 0'
  }
})

const IndexPage = () => {
  const lang =
    i18next.language ||
    (typeof window !== 'undefined' && window.localStorage.i18nextLng)
  console.log('language home', lang)
  return (
    <AltertineLayout image="https://storage.googleapis.com/innobelge-websites.appspot.com/sites/churchill/photo_p4_tennis%20%C3%A9t%C3%A9%20.jpeg">
      <Helmet
        title="Tennis été - Tennis Club Uccle Churchill"
        meta={[
          {
            name: 'description',
            content:
              "Vous souhaitez jouer au tennis cet été? Découvrez ici toutes les informations pratiques liées à la saison d'été"
          },
          { name: 'charset', content: 'UTF-8' }
        ]}
      />
      <section className="project_descr">
        <div className="container mt-100">
          <div className="row center">
            <div className="col-md-8 col-md-offset-2 col-sm-12">
              <div className="section-title">
                <h2 className="mb-50">Tennis été</h2>
                <p className="module-subtitle">
                  Dès le mois d’avril, le Tennis Club Uccle Churchill, petit
                  club familial vous accueille comme chaque année, dans la bonne
                  humeur, pour les premiers échanges de balles.
                </p>
              </div>
            </div>
          </div>

          <div className="row center">
            <div className="col-md-10 col-md-offset-1 wow fadeInUp">
              <p>
                Au programme, toute la saison durant, des cours et des stages
                pour les plus jeunes, mais également toute une série d’activités
                : les nuits des doubles, les animations du samedi, le tournoi
                des familles, …
              </p>
              <p>
                Pour les plus jeunes comme pour les plus âgés, d’autres
                activités vous sont offertes : table de ping pong, pistes de
                pétanque, …
              </p>
              <p>
                Cerise sur le gâteau, l’accueil au club est assuré par une
                famille bien sympathique qui vous propose une restauration de
                qualité, des brunchs le dimanche, des barbecues à la belle
                saison,… Le tout, servi dans une ambiance bon enfant.
              </p>
              <p>
                Alors, tous à vos raquettes et rendez-vous au Tennis Club Uccle
                Churchill dès début du mois d’avril !
              </p>
            </div>
          </div>

          <div className="row center" style={{ marginTop: '5em' }}>
            <div className="col-md-8 col-md-offset-2 col-sm-12">
              <div className="section-title">
                <h2 className="mb-50">Conditions et Tarifs été</h2>
                <p className="module-subtitle">
                  Conditions d'inscription en tant que membre « été » et de
                  réservation des terrains en été.
                </p>
              </div>
            </div>
          </div>
          <div className="row center">
            <div className="col-md-10 col-md-offset-1 wow fadeInUp">
              <table>
                <thead>
                  <tr>
                    <th>{''}</th>
                    <th>Cotisation</th>
                    <th>Terrain</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Adultes</td>
                    <td>100&euro;</td>
                    <td>12&euro; /heure</td>
                  </tr>
                  <tr>
                    <td>Adultes</td>
                    <td>300&euro;</td>
                    <td>Gratuit</td>
                  </tr>
                  <tr>
                    <td>Juniors (-18 ans)</td>
                    <td>50&euro;</td>
                    <td>12&euro; /heure</td>
                  </tr>
                  <tr>
                    <td>Juniors (-18 ans)</td>
                    <td>150&euro;</td>

                    <td>Gratuit</td>
                  </tr>
                  <tr>
                    <td>Autre enfant junior d’une même famille</td>
                    <td>30&euro;</td>

                    <td>12&euro; /heure</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="row center" style={{ marginTop: '5em' }}>
            <div className="col-md-8 col-md-offset-2 col-sm-12">
              <div className="section-title">
                <p className="module-subtitle">
                  Procédure d'inscription pour jouer l’été
                </p>
              </div>
            </div>
          </div>
          <div className="row center" style={{ marginBottom: '7em' }}>
            <div className="col-md-10 col-md-offset-1 wow fadeInUp">
              <p>
                Veuillez svp effectuer un virement (de 30€ à 300€ selon la
                formule choisie) sur le compte du club, BE26 3101 2376 6329 en
                mentionnant en commentaire libre : « NOM Prénom » de la personne
                (ou des personnes) inscrite(s) avec la mention «COTISATION ETE»
              </p>
              <p>
                Vous recevrez alors dans les 5 jours ouvrables un lien donnant
                accès à notre site de réservation.
              </p>
              <p>
                La première fois que vous vous présenterez au club pour
                pratiquer votre sport favori, nous vous demanderons de bien
                vouloir remplir un petit formulaire mentionnant quelques
                informations de base et vous invitant à accepter le{' '}
                <a
                  href="https://storage.googleapis.com/innobelge-websites.appspot.com/sites/churchill/documents/1_Re%CC%80glement%20d'ordre%20inte%CC%81rieur%20TC%20Uccle%20Churchill.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    fontWeight: 'bold',
                    textDecoration: 'underline'
                  }}
                >
                  règlement d’ordre intérieur
                </a>{' '}
                de notre club de tennis.
              </p>
              <p>
                La saison d’été s’étend du début du mois d’avril à la fin du
                mois d’octobre. Attention, à partir de la fin du mois de
                septembre, plus que 2 terrains extérieurs disponibles, mais
                possibilité de réserver des heures volantes sous la bulle aux
                tarifs hiver.
              </p>
            </div>
          </div>
        </div>
      </section>
    </AltertineLayout>
  )
}

export default IndexPage
